import { motion } from "framer-motion";
import TeachingApproachCard from "../cards/TeachingApproachCard";
import {
  realworldProjects,
  smallClassSizes,
  mentorship,
  peerLearning,
} from "../../assets";

function TeachingApproach() {
  const methods = [
    {
      icon: realworldProjects,
      title: "Real-World Projects",
      info: "Each course includes hands-on projects where students solve real-world problems, giving them a portfolio to showcase to future employers.",
    },
    {
      icon: smallClassSizes,
      title: "Small Class Sizes",
      info: "Our small class sizes ensure personalized attention, allowing instructors to focus on each student’s growth.",
    },
    {
      icon: mentorship,
      title: "Mentorship",
      info: "Gain insights from experienced instructors and mentors who guide you through every step of your learning journey.",
    },
    {
      icon: peerLearning,
      title: "Peer Learning",
      info: "We foster a collaborative learning environment where students can learn from each other’s experiences and perspectives.",
    },
  ];
  return (
    <div
      className="flex flex-col min-h-[520px] max-h-[720px] h-auto justify-start md:justify-center items-start md:items-center pb-10 p-7 text-white"
      style={{ background: "var(--Dark-Black, #162B3E)" }}
      // Increase the height of the container to prevent overflow
    >
      <h1 className="text-xl overflow-hidden sm:text-3xl font-bold mb-4">
        Our Teaching Approach
      </h1>
      <p className="mb-5">
        At OIT, we believe that practical learning is key to mastering IT
        skills. That’s why all our courses focus on:
      </p>
      <motion.div className="hidden lg:flex flex-row  h-[600px] overflow-hidden  p-5 pt-20 gap-10">
        {methods.map((method, index) => (
          <motion.div
            className="mb-5"
            key={index}
            animate={{
              y: index % 2 === 0 ? 80 : -60,
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              repeatType: "reverse",
              delay: 2,
              ease: "easeInOut",
            }}>
            <TeachingApproachCard
              title={method.title}
              info={method.info}
              icon={method.icon}
            />
          </motion.div>
        ))}
      </motion.div>{" "}
      <div
        className="flex lg:hidden w-auto p-3 max-w-screen-sm pr-32 justify-start pl-5 md:pl-[450px] lg:pl-5 overflow-x-scroll gap-5"
        style={{ scrollbarWidth: "none" }}>
        {methods.map((method, index) => (
          <div key={index} className="w-full min-w-80 overflow-x-scroll">
            <TeachingApproachCard
              title={method.title}
              info={method.info}
              icon={method.icon}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeachingApproach;
